@font-face {
    font-family: 'Addis Abiy';
    src: url('fonts/AddisAbiy.woff2');
}
@font-face {
    font-family: 'Addis Abiy Caped';
    src: url('fonts/AddisAbiyCaped.woff2');
}
@font-face {
    font-family: 'Addis Demeke';
    src: url('fonts/AddisDemeke.woff2');
}
@font-face {
    font-family: 'Addis Demeke Caped';
    src: url('fonts/AddisDemekeCaped.woff2');
}
@font-face {
    font-family: 'Addis Gedu';
    src: url('fonts/AddisGedu.woff2');
}
@font-face {
    font-family: 'Addis Gedu Caped';
    src: url('fonts/AddisGeduCaped.woff2');
}
@font-face {
    font-family: 'Addis Lemma';
    src: url('fonts/AddisLemma.woff2');
}
@font-face {
    font-family: 'Addis Lemma';
    src: url('fonts/AddisLemma.woff2');
}
@font-face {
    font-family: 'Addis Lemma Caped';
    src: url('fonts/AddisLemmaCaped.woff2');
}

@font-face {
    font-family: 'Addis Lemma Light';
    src: url('fonts/AddisLemmaLight.woff2');
}
@font-face {
    font-family: 'Addis Lemma Light Caped';
    src: url('fonts/AddisLemmaLightCaped.woff2');
}
@font-face {
    font-family: 'Addis Mustafa';
    src: url('fonts/AddisMustafa.woff2');
}
@font-face {
    font-family: 'Addis Mustafa Caped';
    src: url('fonts/AddisMustafaCaped.woff2');
}

@font-face {
    font-family: 'Habesha Eritrea';
    src: url('fonts/HabeshaEritrea.woff2');
}
@font-face {
    font-family: 'Habesha Eritrea Caped';
    src: url('fonts/HabeshaEritreaCaped.woff2');
}
@font-face {
    font-family: 'Habesha Rwanda';
    src: url('fonts/HabeshaRwanda.woff2');
}
@font-face {
    font-family: 'Habesha Rwanda Caped';
    src: url('fonts/HabeshaRwandaCaped.woff2');
}
@font-face {
    font-family: 'Habesha Somalia';
    src: url('fonts/HabeshaSomalia.woff2');
}
@font-face {
    font-family: 'Habesha Somalia Caped';
    src: url('fonts/HabeshaSomaliaCaped.woff2');
}
@font-face {
    font-family: 'Habesha Uganda';
    src: url('fonts/HabeshaUganda.woff2');
}
@font-face {
    font-family: 'Habesha Uganda Caped';
    src: url('fonts/HabeshaUgandaCaped.woff2');
}
@font-face {
    font-family: 'Mulat Abay';
    src: url('fonts/MulatAbay.woff2');
}
@font-face {
    font-family: 'Mulat Abay Caped';
    src: url('fonts/MulatAbayCaped.woff2');
}
@font-face {
    font-family: 'Mulat Abay Thin';
    src: url('fonts/MulatAbayThin.woff2');
}
@font-face {
    font-family: 'Mulat Abay Thin Caped';
    src: url('fonts/MulatAbayThinCaped.woff2');
}
@font-face {
    font-family: 'Mulat Awash';
    src: url('fonts/MulatAwash.woff2');
}
@font-face {
    font-family: 'Mulat Awash Caped';
    src: url('fonts/MulatAwashCaped.woff2');
}
@font-face {
    font-family: 'Mulat Axum';
    src: url('fonts/MulatAxum.woff2');
}
@font-face {
    font-family: 'Mulat Axum Caped';
    src: url('fonts/MulatAxumCaped.woff2');
}
@font-face {
    font-family: 'Mulat Fasil';
    src: url('fonts/MulatFasil.woff2');
}
@font-face {
    font-family: 'Mulat Fasil Caped';
    src: url('fonts/MulatFasil.woff2');
}
@font-face {
    font-family: 'Mulat Omo';
    src: url('fonts/MulatOmo.woff2');
}
@font-face {
    font-family: 'Mulat Omo Caped';
    src: url('fonts/MulatOmoCaped.woff2');
}
@font-face {
    font-family: 'Mulat Tana';
    src: url('fonts/MulatTana.woff2');
}
@font-face {
    font-family: 'Mulat Tana Caped';
    src: url('fonts/MulatTana.woff2');
}
@font-face {
    font-family: 'Mulat Tekeze';
    src: url('fonts/MulatTekeze.woff2');
}
@font-face {
    font-family: 'Mulat Tekeze Caped';
    src: url('fonts/MulatTekezeCaped.woff2');
}

/*[ 8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96 ] */
.derassi-font-size-8 { font-size: 8pt; }
.derassi-font-size-9 { font-size: 9pt; }
.derassi-font-size-10 { font-size: 10pt; }
.derassi-font-size-11 { font-size: 11pt; }
.derassi-font-size-12 { font-size: 12pt; }
.derassi-font-size-14 { font-size: 14pt; }
.derassi-font-size-18 { font-size: 18pt; }
.derassi-font-size-24 { font-size: 24pt; }
.derassi-font-size-30 { font-size: 30pt; }
.derassi-font-size-36 { font-size: 36pt; }
.derassi-font-size-48 { font-size: 48pt; }
.derassi-font-size-60 { font-size: 60pt; }
.derassi-font-size-72 { font-size: 72pt; }
.derassi-font-size-96 { font-size: 96pt; }

.derassi-paragraph {
    /* -ms-font-feature-settings:     "kern" 1 "liga" 1; 
    -o-font-feature-settings:      "kern" 1 "liga" 1; 
    -webkit-font-feature-settings: "kern" 1 "liga" 1;
    font-feature-settings:         "kern" 1 "liga" 1;
     */
    -webkit-font-kerning: normal;
            font-kerning: normal;
    /* -webkit-font-variant-ligatures: discretionary-ligatures;
            font-variant-ligatures: discretionary-ligatures;
    text-rendering: optimizeLegibility; */
  }

body {
    background-color: gainsboro;
}

::-moz-selection { /* Code for Firefox */
    background: '';
  }
  
  ::selection {
    background: '';
  }

/* .derassi-unformatted {
 
} */
/* iframe.picker {
    font-family: "Addis Lemma" !important;
} */
/* file menu */
.derassi-upper-menu-button {
    color: 'black';
    background-color: 'transparent';
}
.derassi-upper-menu-dropdwon {
    position: 'absolute';
    display: 'inline-block';
    z-index: 3;
    top: 35;
}
.derassi-upper-menu-list {
    min-width: 200;
}


/* dont set height on paragraph class because javascript needs it changing */
/* .paragraph {
    height:  11in;;

} */

div[contenteditable="true"]:focus {
  outline: none;
}
div[contenteditable="true"] {
    caret-color: black;
}
button:focus {
    outline: none;
}
.derassi-selected {
    background: #ACCEF7;
}
.derassi-bold {
   font-weight: bold;
}
.derassi-italic {
    font-style: italic;
 }
/* .derassi-header-footer:hover {
    outline: solid 1px aqua !important;
} */
.derassi-header-pagenum, .derassi-footer-pagenum {
    visibility: hidden;
}
/* .derassi-paragraph-forwarding {
    background-color: aqua !important;
}
.derassi-paragraph-backwarding {
    background-color: burlywood !important;
} */

.derassi-table table {
    border-collapse: collapse;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .derassi-table td, .derassi-table th {
    border: 1px solid lightgray;
    padding: 3px;
    width: auto;
  }
  
 .derassi-table tr:nth-child(even){background-color: #f2f2f2;}
  
 .derassi-table tr:hover {background-color: #ddd;}
  
 .derassi-table {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    /* background-color: #44ddff; */
}
.derassi-text {
    text-indent: 4px;
}
.derassi-text:hover {
    border: solid grey 1px;
}
.derassi-title span {
    font-size: 30px !important;
    font-weight: bold !important;
}
.derassi-title {
    padding: 10px;
}
.derassi-subtitle {
    padding: 10px;
    padding-right: 20px;
    opacity: 0.7;
}
.derassi-subtitle span {
    font-size: 24px !important;
}
.derassi-h1 span {
    font-size: 34px !important;
}
.derassi-h2 span {
    font-size: 24px !important;
}
.derassi-h3 span {
    font-size: 10px !important;
}

caption {
    border: 1px solid lightgray;
    border-bottom: none;
    padding: 3px;
    width: auto;
    opacity: 0.7;
    /* caption-side: bottom; */
}

/* based on mui button primary */
.derassi-upload-button {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 8px;
    border-radius: 5px;
    color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
}
.derassi-upload-button:hover {
    background-color:  rgba(63, 81, 181, 0.1);;
}

.derassi-caption > * {
  font-size: 80% !important; 
}
.intLink:hover { cursor: pointer; }
.menu div {  display: inline; 
         white-space: nowrap; 
    }

/* @page {
    size: 8.5in 11in;
    margin: 0in;
}
@page :first {
    margin: 0in;
    padding: 0in;
} */
.ethiopic li {
    list-style-type: ethiopic-halehame-ti-et;
    list-style-type: -moz-ethiopic-halehame-ti-et;
}
.lower-alpha li {
    list-style-type: lower-alpha;
}
.upper-roman li {
    list-style-type: upper-roman;
}

.decimal li {
    list-style-type: decimal;
}

.upper-alpha li {
    list-style-type: upper-alpha
}
.squaer li {
    list-style-type: square;
}



@media print {
    .no-print {
        display: none;
    }
    @page {
      size: A4;
      margin: 0px;
      padding: 0in;
    }
    #derassi-pages {
        transform: translateY(-160px);
        padding-top: 0px;
        margin: 0in;
        padding: 0in;
        zoom: 1;
    }
    .derassi-page {
        margin: 0in;
        
        margin-bottom: 0in;
        padding: 0in;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

/* iframe.picker-dialog {
    font-family: 'Addis Lemma' !important;
  } */
