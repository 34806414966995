@font-face {
    font-family: 'Addis Abiy';
    src: url(/static/media/AddisAbiy.3807164d.woff2);
}
@font-face {
    font-family: 'Addis Abiy Caped';
    src: url(/static/media/AddisAbiyCaped.c3630583.woff2);
}
@font-face {
    font-family: 'Addis Demeke';
    src: url(/static/media/AddisDemeke.dd744011.woff2);
}
@font-face {
    font-family: 'Addis Demeke Caped';
    src: url(/static/media/AddisDemekeCaped.a57bec45.woff2);
}
@font-face {
    font-family: 'Addis Gedu';
    src: url(/static/media/AddisGedu.8992a563.woff2);
}
@font-face {
    font-family: 'Addis Gedu Caped';
    src: url(/static/media/AddisGeduCaped.e23fe539.woff2);
}
@font-face {
    font-family: 'Addis Lemma';
    src: url(/static/media/AddisLemma.3c5cab61.woff2);
}
@font-face {
    font-family: 'Addis Lemma';
    src: url(/static/media/AddisLemma.3c5cab61.woff2);
}
@font-face {
    font-family: 'Addis Lemma Caped';
    src: url(/static/media/AddisLemmaCaped.7c671c69.woff2);
}

@font-face {
    font-family: 'Addis Lemma Light';
    src: url(/static/media/AddisLemmaLight.13edecd7.woff2);
}
@font-face {
    font-family: 'Addis Lemma Light Caped';
    src: url(/static/media/AddisLemmaLightCaped.769065f6.woff2);
}
@font-face {
    font-family: 'Addis Mustafa';
    src: url(/static/media/AddisMustafa.7afa2692.woff2);
}
@font-face {
    font-family: 'Addis Mustafa Caped';
    src: url(/static/media/AddisMustafaCaped.b0518bc4.woff2);
}

@font-face {
    font-family: 'Habesha Eritrea';
    src: url(/static/media/HabeshaEritrea.87f567e6.woff2);
}
@font-face {
    font-family: 'Habesha Eritrea Caped';
    src: url(/static/media/HabeshaEritreaCaped.ec78d768.woff2);
}
@font-face {
    font-family: 'Habesha Rwanda';
    src: url(/static/media/HabeshaRwanda.f0d51795.woff2);
}
@font-face {
    font-family: 'Habesha Rwanda Caped';
    src: url(/static/media/HabeshaRwandaCaped.890640a7.woff2);
}
@font-face {
    font-family: 'Habesha Somalia';
    src: url(/static/media/HabeshaSomalia.87cd44a5.woff2);
}
@font-face {
    font-family: 'Habesha Somalia Caped';
    src: url(/static/media/HabeshaSomaliaCaped.d8591ebb.woff2);
}
@font-face {
    font-family: 'Habesha Uganda';
    src: url(/static/media/HabeshaUganda.8490eeb7.woff2);
}
@font-face {
    font-family: 'Habesha Uganda Caped';
    src: url(/static/media/HabeshaUgandaCaped.70a527b8.woff2);
}
@font-face {
    font-family: 'Mulat Abay';
    src: url(/static/media/MulatAbay.37d45f36.woff2);
}
@font-face {
    font-family: 'Mulat Abay Caped';
    src: url(/static/media/MulatAbayCaped.bf0556a2.woff2);
}
@font-face {
    font-family: 'Mulat Abay Thin';
    src: url(/static/media/MulatAbayThin.0296f88b.woff2);
}
@font-face {
    font-family: 'Mulat Abay Thin Caped';
    src: url(/static/media/MulatAbayThinCaped.296f3d56.woff2);
}
@font-face {
    font-family: 'Mulat Awash';
    src: url(/static/media/MulatAwash.5a28114b.woff2);
}
@font-face {
    font-family: 'Mulat Awash Caped';
    src: url(/static/media/MulatAwashCaped.87717b4e.woff2);
}
@font-face {
    font-family: 'Mulat Axum';
    src: url(/static/media/MulatAxum.b34055a5.woff2);
}
@font-face {
    font-family: 'Mulat Axum Caped';
    src: url(/static/media/MulatAxumCaped.e98e6a43.woff2);
}
@font-face {
    font-family: 'Mulat Fasil';
    src: url(/static/media/MulatFasil.8d1f77d4.woff2);
}
@font-face {
    font-family: 'Mulat Fasil Caped';
    src: url(/static/media/MulatFasil.8d1f77d4.woff2);
}
@font-face {
    font-family: 'Mulat Omo';
    src: url(/static/media/MulatOmo.bf2203a8.woff2);
}
@font-face {
    font-family: 'Mulat Omo Caped';
    src: url(/static/media/MulatOmoCaped.20482613.woff2);
}
@font-face {
    font-family: 'Mulat Tana';
    src: url(/static/media/MulatTana.a031e8dc.woff2);
}
@font-face {
    font-family: 'Mulat Tana Caped';
    src: url(/static/media/MulatTana.a031e8dc.woff2);
}
@font-face {
    font-family: 'Mulat Tekeze';
    src: url(/static/media/MulatTekeze.7bea4075.woff2);
}
@font-face {
    font-family: 'Mulat Tekeze Caped';
    src: url(/static/media/MulatTekezeCaped.55ba2293.woff2);
}

/*[ 8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96 ] */
.derassi-font-size-8 { font-size: 8pt; }
.derassi-font-size-9 { font-size: 9pt; }
.derassi-font-size-10 { font-size: 10pt; }
.derassi-font-size-11 { font-size: 11pt; }
.derassi-font-size-12 { font-size: 12pt; }
.derassi-font-size-14 { font-size: 14pt; }
.derassi-font-size-18 { font-size: 18pt; }
.derassi-font-size-24 { font-size: 24pt; }
.derassi-font-size-30 { font-size: 30pt; }
.derassi-font-size-36 { font-size: 36pt; }
.derassi-font-size-48 { font-size: 48pt; }
.derassi-font-size-60 { font-size: 60pt; }
.derassi-font-size-72 { font-size: 72pt; }
.derassi-font-size-96 { font-size: 96pt; }

.derassi-paragraph {
    /* -ms-font-feature-settings:     "kern" 1 "liga" 1; 
    -o-font-feature-settings:      "kern" 1 "liga" 1; 
    -webkit-font-feature-settings: "kern" 1 "liga" 1;
    font-feature-settings:         "kern" 1 "liga" 1;
     */
    -webkit-font-kerning: normal;
            -webkit-font-feature-settings: "kern";
                    font-feature-settings: "kern";
            font-kerning: normal;
    /* -webkit-font-variant-ligatures: discretionary-ligatures;
            font-variant-ligatures: discretionary-ligatures;
    text-rendering: optimizeLegibility; */
  }

body {
    background-color: gainsboro;
}

::-moz-selection { /* Code for Firefox */
    background: '';
  }
  
  ::selection {
    background: '';
  }

/* .derassi-unformatted {
 
} */
/* iframe.picker {
    font-family: "Addis Lemma" !important;
} */
/* file menu */
.derassi-upper-menu-button {
    color: 'black';
    background-color: 'transparent';
}
.derassi-upper-menu-dropdwon {
    position: 'absolute';
    display: 'inline-block';
    z-index: 3;
    top: 35;
}
.derassi-upper-menu-list {
    min-width: 200;
}


/* dont set height on paragraph class because javascript needs it changing */
/* .paragraph {
    height:  11in;;

} */

div[contenteditable="true"]:focus {
  outline: none;
}
div[contenteditable="true"] {
    caret-color: black;
}
button:focus {
    outline: none;
}
.derassi-selected {
    background: #ACCEF7;
}
.derassi-bold {
   font-weight: bold;
}
.derassi-italic {
    font-style: italic;
 }
/* .derassi-header-footer:hover {
    outline: solid 1px aqua !important;
} */
.derassi-header-pagenum, .derassi-footer-pagenum {
    visibility: hidden;
}
/* .derassi-paragraph-forwarding {
    background-color: aqua !important;
}
.derassi-paragraph-backwarding {
    background-color: burlywood !important;
} */

.derassi-table table {
    border-collapse: collapse;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .derassi-table td, .derassi-table th {
    border: 1px solid lightgray;
    padding: 3px;
    width: auto;
  }
  
 .derassi-table tr:nth-child(even){background-color: #f2f2f2;}
  
 .derassi-table tr:hover {background-color: #ddd;}
  
 .derassi-table {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    /* background-color: #44ddff; */
}
.derassi-text {
    text-indent: 4px;
}
.derassi-text:hover {
    border: solid grey 1px;
}
.derassi-title span {
    font-size: 30px !important;
    font-weight: bold !important;
}
.derassi-title {
    padding: 10px;
}
.derassi-subtitle {
    padding: 10px;
    padding-right: 20px;
    opacity: 0.7;
}
.derassi-subtitle span {
    font-size: 24px !important;
}
.derassi-h1 span {
    font-size: 34px !important;
}
.derassi-h2 span {
    font-size: 24px !important;
}
.derassi-h3 span {
    font-size: 10px !important;
}

caption {
    border: 1px solid lightgray;
    border-bottom: none;
    padding: 3px;
    width: auto;
    opacity: 0.7;
    /* caption-side: bottom; */
}

/* based on mui button primary */
.derassi-upload-button {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 7px;
    padding-bottom: 8px;
    border-radius: 5px;
    color: #3f51b5;
    border: 1px solid rgba(63, 81, 181, 0.5);
}
.derassi-upload-button:hover {
    background-color:  rgba(63, 81, 181, 0.1);;
}

.derassi-caption > * {
  font-size: 80% !important; 
}
.intLink:hover { cursor: pointer; }
.menu div {  display: inline; 
         white-space: nowrap; 
    }

/* @page {
    size: 8.5in 11in;
    margin: 0in;
}
@page :first {
    margin: 0in;
    padding: 0in;
} */
.ethiopic li {
    list-style-type: ethiopic-halehame-ti-et;
    list-style-type: -moz-ethiopic-halehame-ti-et;
}
.lower-alpha li {
    list-style-type: lower-alpha;
}
.upper-roman li {
    list-style-type: upper-roman;
}

.decimal li {
    list-style-type: decimal;
}

.upper-alpha li {
    list-style-type: upper-alpha
}
.squaer li {
    list-style-type: square;
}



@media print {
    .no-print {
        display: none;
    }
    @page {
      size: A4;
      margin: 0px;
      padding: 0in;
    }
    #derassi-pages {
        -webkit-transform: translateY(-160px);
                transform: translateY(-160px);
        padding-top: 0px;
        margin: 0in;
        padding: 0in;
        zoom: 1;
    }
    .derassi-page {
        margin: 0in;
        
        margin-bottom: 0in;
        padding: 0in;
        border: medium none currentColor;
        border: initial;
        border-radius: 0;
        border-radius: initial;
        width: auto;
        width: initial;
        min-height: 0;
        min-height: initial;
        box-shadow: none;
        box-shadow: initial;
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        page-break-after: always;
    }
}

/* iframe.picker-dialog {
    font-family: 'Addis Lemma' !important;
  } */

.croppr-container *{user-select:none;-moz-user-select:none;-webkit-user-select:none;-ms-user-select:none;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box}.croppr-container img{vertical-align:middle;max-width:100%}.croppr{position:relative;display:inline-block}.croppr-handle,.croppr-imageClipped,.croppr-overlay,.croppr-region{position:absolute;top:0}.croppr-overlay{background:rgba(0,0,0,.5);right:0;bottom:0;left:0;z-index:1;cursor:crosshair}.croppr-region{border:1px dashed rgba(0,0,0,.5);z-index:3;cursor:move}.croppr-imageClipped{right:0;bottom:0;left:0;z-index:2;pointer-events:none}.croppr-handle{border:1px solid #000;background-color:#fff;width:10px;height:10px;z-index:4}
